<template>
  <v-container>
    <h1>404</h1>
    <h2>Rommet finnes ikke!</h2>
    <br />
    <h3>Prøv et annet romnavn</h3>
    <br />
    <h5>Obs! Romnavn kan ikke inneholde spesialtegn (#, /, $, osv)</h5>
  </v-container>
</template>

<script>
export default {
  name: 'Error404',
  mounted() {
    window.analytics.page(this.name)
  }
}
</script>
