var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", [
    _c("h1", [_vm._v("404")]),
    _c("h2", [_vm._v("Rommet finnes ikke!")]),
    _c("br"),
    _c("h3", [_vm._v("Prøv et annet romnavn")]),
    _c("br"),
    _c("h5", [
      _vm._v("Obs! Romnavn kan ikke inneholde spesialtegn (#, /, $, osv)")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }